import React from "react"
import Layout from "@layout/Layout"
import PostCards from "@components/PostCards"
import Header from "@layout/Header/Header"
import Tabs from "@components/Tabs"
import { graphql } from "gatsby"
import { Container, Card, Heading } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import Reveal from "@components/Reveal"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Card variant="horizontal-cover-hero " sx={{ mt: "7rem" }}>
        <Tabs variant="dots" position="bottom" arrows>
          <Reveal
            effect="fadeInRight"
            effectOut="fadeOutLeft"
            delay={0.2}
            duration={0.7}
            threshold={0.7}
          >
            <StaticImage src="../static/slider7.jpg" />
          </Reveal>
          <Reveal
            effect="fadeInRight"
            effectOut="fadeOutLeft"
            delay={0.2}
            duration={0.7}
            threshold={0.7}
          >
            <StaticImage src="../static/slider2.jpg" />
          </Reveal>
          <Reveal
            effect="fadeInRight"
            effectOut="fadeOutLeft"
            delay={0.2}
            duration={0.7}
            threshold={0.7}
          >
            <StaticImage src="../static/slider3.jpg" />
          </Reveal>
          <Reveal
            effect="fadeInRight"
            effectOut="fadeOutLeft"
            delay={0.2}
            duration={0.7}
            threshold={0.7}
          >
            <StaticImage src="../static/slider4.jpg" />
          </Reveal>
          <Reveal
            effect="fadeInRight"
            effectOut="fadeOutLeft"
            delay={0.2}
            duration={0.7}
            threshold={0.7}
          >
            <StaticImage src="../static/slider5.jpg" />
          </Reveal>
          <Reveal
            effect="fadeInRight"
            effectOut="fadeOutLeft"
            delay={0.2}
            duration={0.7}
            threshold={0.7}
          >
            <StaticImage src="../static/slider6.jpg" />
          </Reveal>
          <Reveal
            effect="fadeInRight"
            effectOut="fadeOutLeft"
            delay={0.2}
            duration={0.7}
            threshold={0.7}
          >
            <StaticImage src="../static/slider1.jpg" />
          </Reveal>
        </Tabs>
      </Card>
      <Heading
        variant="h1"
        sx={{ py: [2, 5], fontWeight: "600", textAlign: "center", mt: [4, 0] }}
      >
        Products
      </Heading>
      <Container>
        <PostCards postEdges={data.all.edges} />
      </Container>
    </Layout>
  )
}

export default IndexPage

export const Query = graphql`
  query IndexQuery {
    all: allGoogleAlkatrszekSheet(
      filter: { sT_TUSZ: { ne: "ELADVA" } }
      limit: 21
    ) {
      edges {
        node {
          sKU
          sT_TUSZ
          tERM_KN_V
          kATEG_RIA_1Szint
          kATEG_RIA_2Szint
          kATEG_RIA_3Szint
          kATEG_RIA_4Szint
          bREADCRUMBLV1
          bREADCRUMBLV2
          bREADCRUMBLV3
          bREADCRUMBLV4
          webshop__r
          sLUG
          id
          fot_11
          fot_19
          fot_18
          fot_17
          fot_15
          fot_16
          fot_14
          fot_13
          fot_12
          fot_110
        }
      }
    }
  }
`
