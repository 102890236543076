import React from "react"
import { Box, Card, Link, Flex } from "theme-ui"
import { Link as GLink } from "gatsby"

const PostCards = props => {
  let PostCard = []

  props.postEdges.forEach(postEdge => {
    PostCard.push({
      title: postEdge.node.tERM_KN_V,
      slug: postEdge.node.sLUG,
      id: postEdge.node.id,
      sku: postEdge.node.sKU,
      sold: postEdge.node.sT_TUSZ,
      price: postEdge.node.webshop__r,
      image1: postEdge.node.fot_11,
      image2: postEdge.node.fot_12,
      thumbnail1: postEdge.node.fot_11
        ? postEdge.node.fot_11.replace("w_200", "w_400")
        : null,
      thumbnail2: postEdge.node.fot_12
        ? postEdge.node.fot_12.replace("w_200", "w_400")
        : null,
    })
  })

  return (
    <Box
      sx={{
        maxWidth: "container",
        margin: "auto",
      }}
    >
      {PostCard.map(post => (
        // post.title !== null &&
        post.image1 &&
        <Box
          sx={{
            size: ["full", "1/2", "1/4", "1/4"],
            mb: 4,
            display: "inline-grid",
          }}
        >
          <Box
            sx={{
              m: "2",
              position: "relative",
              height: "17rem",
            }}
          >
            <Link as={GLink} key={post.id} to={`/${post.slug}`}>
              {post.image1 && (
                <Card
                  variant="cards.interactive"
                  style={{
                    backgroundImage: "url(" + post.thumbnail1 + ")",
                    backgroundSize: "100%",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "11rem",
                  }}
                />
              )}
              {!post.image1 && (
                <Card
                  variant="cards.interactive"
                  style={{
                    backgroundImage: "url(" + post.thumbnail2 + ")",
                    backgroundSize: "100%",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "11rem",
                  }}
                />
              )}
              <Flex sx={{ p: 3 }}>
                <Box sx={{ flexBasis: post.price && "2/3" }}>
                  <Link
                    variant="mute"
                    sx={{ fontSize: "0.875rem" }}
                    as={GLink}
                    to={`/${post.slug}`}
                  >
                    {post.title.substring(0, 38) +
                      (post.title.length > 38 ? "..." : "")}
                  </Link>
                </Box>
                {post.sold === "ELADVA\n WEBSHOPBA FELTÖLTVE" ? (
                  <Box sx={{ py: 3, flexBasis: "1/3", textAlign: "right" }}>
                    ELADVA
                  </Box>
                ) : (
                  <Box sx={{ py: 3, flexBasis: "1/3", textAlign: "right" }}>
                    {post.price ? (
                      <Link
                        variant="mute"
                        sx={{ fontSize: "1rem", fontWeight: "600" }}
                        as={GLink}
                        to={`/${post.slug}`}
                      >
                        {post.price} FT
                      </Link>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
              </Flex>
            </Link>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default PostCards
